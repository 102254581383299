﻿<template>
    <div class="splash-screen">
        <div class="container">
            <div class="headline">
                <h1>State-of-the-art intumescent paint</h1>
                <p class="d-md-none d-sm-block ">
                    Tested to <b>ASTM E84</b> and <b>E119</b> standards for 1 and 2 hours fire rating.
                </p>
                <p class="d-none d-md-block">
                    We manufacture an outperforming <b>FlameOFF&#174; Fire Resistant Paint</b> tested to <b>ASTM E84</b> and <b>E119</b> standards and <b>FlameOFF&#174; Fire Retardant Spray</b> tested to <b>NFPA 701</b> standards.
                </p>
                <a href="#" class="video-popup-link" @click="videoIsOpen = true" aria-label="Play video">
                    <div class="play-btn compact d-md-none">
                        <nuxt-icon name="playBtnCompact" filled />
                    </div>
                    <div class="play-btn d-none d-md-block">
                        <nuxt-icon name="playBtn" filled />
                    </div>
                </a>
                <div class="video-description">Watch video <span class="">(2 min)</span></div>
            </div>
        </div>
    </div>

    <MediaModal videoId="-kXsj3J0NM0" v-model="videoIsOpen" />
</template>

 <script lang="ts" setup>
     const videoIsOpen = ref(false);
 </script>

 <style lang="scss">
     @import '@/assets/styles/bootstrap/common';
     @import '@/assets/styles/_mixins';

     .splash-screen {
         background: url(/images/home_background_mobile.webp) #2d3f55 no-repeat;
         background-size: cover;
         background-position: 50% 50%;
         color: #fff;
         margin-top: -120px;
         overflow: auto;

         @include media-breakpoint-up(md) {
             background: url(/images/home_background.webp) #2d3f55 no-repeat;
             background-size: cover;
             background-position: 50% 50%;
         }

         @include media-breakpoint-up(lg) {
             height: 735px;
             margin-top: -165px;
         }

         .headline {
             text-align: center;
             vertical-align: middle;
             margin-top: 145px;

             @include media-breakpoint-up(lg) {
                 padding: 0 18%;
                 margin-top: 210px;
             }

             h1 {
                 @include font-roboto-medium(36px);
                 letter-spacing: 1px;
                 padding: 0;
                 margin: 0 0 15px 0;

                 @include media-breakpoint-up(md) {
                     @include font-roboto-medium(42px);
                 }
             }

             p {
                 @include font-source-sans-pro-regular(18px);
                 padding-top: 20px;
                 line-height: 29px;
                 max-width: 550px;
                 margin-left: auto;
                 margin-right: auto;
             }

             .video-popup-link {
                 @media(max-height: 768px) {
                     padding-top: 25px;
                 }

                 outline: none;
                 display: inline-block;
                 padding-top: 35px;

                 .play-btn {
                     width: 124px;
                     height: 197px;

                     &.compact {
                         width: 104px;
                         height: 104px;
                     }

                     .outer-flame {
                         opacity: 0.5;
                     }

                     .inner-flame {
                         opacity: 0.3;
                     }
                 }

                 &:hover, &:focus {
                     text-decoration: none;

                     .play-btn {
                         svg {
                             .outer-flame {
                                 opacity: 0.8;
                             }

                             .inner-flame {
                                 opacity: 0.4;
                             }
                         }
                     }
                 }
             }

             .video-description {
                 @include font-source-sans-pro-semi-medium(18px);
                 padding: 5px 0 25px 0;
                 color: #fff;
                 text-transform: uppercase;
                 letter-spacing: 1px;

                 span {
                     color: #d8dade;
                 }
             }
         }
     }
</style>